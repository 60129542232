import React from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/react';
import SanityImage from 'gatsby-plugin-sanity-image';
import { setName } from '../extra/util';
import SEO from '../components/seo';
import BlockContent from '../components/block-content';
import Gallery from '../components/gallery';
import Card from '../components/card';

export default function Program({ data }) {
  const lf = new Intl.ListFormat('en-AU');
  const df = new Intl.DateTimeFormat('en-AU', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
  const dfd = new Intl.DateTimeFormat('en-AU', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

  setName(data.program?.title);

  function invertHex(hex) {
    if (hex) {
      if (hex.charAt(0) === '#') {
        hex = hex.substr(1);
      }

      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }

      return (Number(`0x1${hex}`) ^ 0xFFFFFF).toString(16).substr(1).toUpperCase()
    }
  }

  function stringToColor(str) {
    let hash = 0;

    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 0xff;
      color += ('00' + value.toString(16)).substr(-2);
    }

    return color;
  }

  function getContrastTextColor(hexBackgroundColor) {
    if (hexBackgroundColor.charAt(0) === '#') {
      hexBackgroundColor = hexBackgroundColor.substr(1);
    }

    const r = parseInt(hexBackgroundColor.substr(1, 2), 16);
    const g = parseInt(hexBackgroundColor.substr(3, 2), 16);
    const b = parseInt(hexBackgroundColor.substr(5, 2), 16);

    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    return luminance > 0.5 ? '#000000' : '#FFFFFF';
  }

  function getDateRange(startDate, endDate) {
    const startParts = df.formatToParts(new Date(startDate));
    const endParts = df.formatToParts(new Date(endDate));

    return `${type('day', startParts)} ${type('month', startParts)} → ${type('day', endParts)} ${type('month', endParts)} ${type('year', endParts)}`;

    function type(type, array) {
      return array.find((item) => item.type === type).value;
    }
  }


  return (
    <div
      className="type--50"
      css={css`
        color: ${(data.program.featuredImage) ? data.program.featuredImage.asset.metadata.palette?.vibrant?.foreground : getContrastTextColor(stringToColor(`/program/${data.program.slug?.current}`))};
        background-color: ${(data.program.featuredImage) ? data.program.featuredImage.asset.metadata.palette?.vibrant?.background : stringToColor(`/program/${data.program.slug?.current}`)};
        padding: var(--margin);

        a {
          color: ${(data.program.featuredImage) ?
            `#${invertHex(data.program.featuredImage.asset.metadata.palette?.vibrant?.foreground.substring(1))}` :
            `#${invertHex(getContrastTextColor(stringToColor(`/program/${data.program.slug?.current}`)))}`
          };

          &:hover {
            color: ${(data.program.featuredImage) ?
              data.program.featuredImage.asset.metadata.palette?.vibrant?.foreground :
              getContrastTextColor(stringToColor(`/program/${data.program.slug?.current}`))
            };
          }
        }
      `}
    >
      <div
        css={css`
          text-align: center;
          margin-bottom: var(--largeMargin);
        `}
      >
        <h1
          className="type--50"
        >
          &ldquo;{data.program.title}&rdquo;<br />
          {data.program.people.length > 0 && lf.format(data.program.people.map((person) => person.name))}<br />
          {getDateRange(data.program.startDate, data.program.endDate)}
        </h1>

        {data.program.events.length > 0 && <div
          css={css`
            margin-top: 40px;
          `}
        >
          {data.program.events.filter((event) => event.start).map((event) => <h2
            className="type--35"
            css={css`

              &:last-of-type {
                margin-bottom: 0;
              }
            `}
          >
            {event.start && <>
              {event.end && <>{event.title}, {dfd.formatRange(new Date(event.start), new Date(event.end)).replaceAll(':00', '')}</>}
              {!event.end && <>{event.title}, {dfd.format(new Date(event.start)).replaceAll(':00', '')}</>}
            </>}
          </h2>)}
        </div>}
      </div>
      {data.program.featuredImage?.asset && <div>
        <SanityImage
          {...data.program.featuredImage}
          width={1980}
        />
        <div
          className="type--20"
          css={css`
            margin-bottom: var(--largeMargin);
          `}
        >{data.program.featuredImage.asset.description}</div>
      </div>}
      <BlockContent
        css={css`
          margin-bottom: var(--extraLargeMargin);
        `}
        content={data.program._rawContent}
      />
      {data.program.images.length > 0 && <Gallery images={data.program.images} />}
      {data.program.people.length > 0 && <div
        css={css`
          max-width: 920px;
          padding-top: var(--gap);
          border-top: var(--borderWidth) solid var(--white);
          margin: 0 auto;
          margin-bottom: var(--extraLargeMargin);
        `}
      >
        {data.program.people.map((person, i) => <div
          key={i}
        >
          <BlockContent
            className="type--20--important"
            css={css`
              margin-bottom: var(--margin);
            `}
            content={person._rawDescription}
          />
        </div>)}
      </div>}
      {data.program.related.length > 0 && <div
        css={css`
        `}
      >
        <h3
          className="type--50"
          css={css`
            padding-top: 40px;
            border-top: var(--borderWidth) solid var(--white);
            margin-bottom: 50px;
          `}
        >Related</h3>
        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
            gap: var(--gap);
          `}
        >
          {data.program.related.map((item, i) => <Card
              key={i}
              css={css`
                grid-column: span 1;
              `}
              small
              url={`/program/${item.slug.current}`}
              image={item.featuredImage}
              title={<>
                &ldquo;{item.title}&rdquo;<br />
                {item.people.length > 0 && lf.format(item.people.map((person) => person.name))}<br />
                {getDateRange(item.startDate, item.endDate)}
              </>}
              oppositeFrame
            />)
          }
        </div>
      </div>}
    </div>
  );
}

export function Head({ data }) {
  return <SEO title={`West Space – ${data.program?.title}`} />
}

export const query = graphql`
  query ProgramTemplateQuery($slug: String!) {
    program: sanityProgram(slug: { current: { eq: $slug } }) {
      id
      slug {
        current
      }
      title
      startDate
      endDate
      events {
        title
        start
        end
      }
      featuredImage {
        ...Image
        asset {
          metadata {
            palette {
              vibrant {
                background
                foreground
              }
            }
          }
        }
      }
      _rawContent(resolveReferences: { maxDepth: 10 })
      people {
        name
        _rawDescription(resolveReferences: { maxDepth: 10 })
      }
      images {
        ...Image
      }
      related {
        title
        slug {
          current
        }
        startDate
        endDate
        featuredImage {
          ...Image
        }
        people {
          name
        }
      }
    }
  }
`;
